$ = jQuery;

$(document).ready(function(){
	var args = {
		slidesToScroll: 1,
		lazyload: 'ondemand',
		infinite: true,
		prevArrow: '<button type="button" class="slick-prev slick-arrow bg-transparent py-0 px-0 border-0" aria-label="Go to previous slide"><span class="fal fa-arrow-left"></span></button>',
		nextArrow: '<button type="button" class="slick-next slick-arrow bg-transparent py-0 px-0 border-0" aria-label="Go to next slide"><span class="fal fa-arrow-right"></span></button>',
			
		responsive: [
			{
				breakpoint: 4000
				, settings: {
					slidesToShow: 4
				}
			}
			, {
				breakpoint: 992
				, settings: {
					slidesToShow: 3
				}
			}
			, {
				breakpoint: 768
				, settings: {
					arrows: false
					, slidesToShow: 2
				}
			}
			, {
				breakpoint: 576
				, settings: {
					slidesToShow: 1
					, dots: true
				}
			}
		]
	};
	$('.card-deck').slick(args);
	//console.log(args);


	// Listen for button click and show more/less in Message from Director
	var myBtn = document.getElementById("readBtn");
	myBtn.addEventListener("click", function(){
		var secondText = document.getElementById("second-msg");
		var myBtn = document.getElementById("readBtn");

		if ( "Read less" === myBtn.innerHTML ) {
			secondText.style.display = "none";
			myBtn.innerHTML = "Read more";
		} else {
			secondText.style.display = "block";
			myBtn.innerHTML = "Read less";
		}

		if ( myBtn.classList.contains('more') ) {
			myBtn.classList.remove('more');
			myBtn.classList.add('less');
			secondText.style.display = "block";
			myBtn.innerHTML = "Read less <span class='fas fa-caret-up'></span>";
		} else {
			myBtn.classList.remove('less');
			myBtn.classList.add('more');
			secondText.style.display = "none";
			myBtn.innerHTML = "Read more <span class='fas fa-caret-down'></span>";
		}

	 });
});


// {
// 	breakpoint: 992,
// 	// settings: 'unslick'
// 	settings: {
// 		slidesToShow: 2
// 	}
// },
// {
// 	breakpoint: 576,
// 	// settings: 'unslick'
// 	settings: {
// 		slidesToShow: 1
// 	}
// },